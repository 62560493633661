import React, { lazy } from 'react';
import { BrowserRouter, Router, Route, Switch } from "react-router-dom";
import {metrics} from "react-metrics";

import Layout from "../components/Layout";

import history from './history';
import MetricsConfig from "./metrics.config";

const AsyncHome = lazy(() => import('../containers/Home'));
const AsyncBookingBridge = lazy(() => import('../containers/BookingBridge'));
const AsyncBookingBridgeForm = lazy(() => import('../containers/BookingBridge/Form'));
const AsyncLeaderboard = lazy(() => import('../containers/Leaderboard'));
const AsyncSkiDashboard = lazy(() => import('../containers/SkiDashboard'));
const AsyncMyRewards = lazy(() => import('../containers/MyRewards'));
const AsyncSnowConditions = lazy(() => import('../containers/SnowConditions'));
const AsyncWeather = lazy(() => import('../containers/Weather'));
const AsyncSnowReport = lazy(() => import('../containers/SnowReports'));
const AsyncGPSTrack = lazy(() => import('../containers/GPSTrack'));
const AsyncGPSTrackPlay = lazy(() => import('../containers/GPSTrackPlay'));
const AsyncRevoReservations = lazy(() => import('../containers/RevoReservations'));


const AsyncNotFound = lazy(() => import('../containers/NotFound'));

const DecoratedLayout = metrics(MetricsConfig)(Layout);

export default () => {
    window.onscroll = function() {};
    
    return(
    <BrowserRouter>
        <Router history={history}>
            <Switch>
                <DecoratedLayout>
                    <Switch>
                        <Route exact path="/" component={AsyncHome} />
                        <Route exact path="/booking/bridge/:id" component={AsyncBookingBridgeForm} />
                        <Route exact path="/booking/bridge" component={AsyncBookingBridge} />
                        <Route exact path="/booking/myrewards" component={AsyncMyRewards} />
                        <Route exact path="/app/leaderboard" component={AsyncLeaderboard} />
                        <Route exact path="/app/revoreservations" component={AsyncRevoReservations} />
                        <Route exact path="/app/skidashboard" component={AsyncSkiDashboard} />
                        <Route exact path="/app/snowconditions" component={AsyncSnowConditions} />
                        <Route exact path="/app/weather" component={AsyncWeather} />
                        <Route exact path="/app/snowreport" component={AsyncSnowReport} />
                        <Route exact path="/app/gpstrack2" component={AsyncGPSTrackPlay} />
                        <Route exact path="/app/gpstrack" component={AsyncGPSTrack} />
                        <Route component={AsyncNotFound} />
                    </Switch>
                </DecoratedLayout>
            </Switch>
        </Router>
    </BrowserRouter>
    );
}