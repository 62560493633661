import 'core-js/stable';
import 'regenerator-runtime/runtime';

import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';

import App from './containers/App';

import QueryStringRetriever from './utils/QueryStringRetriever';

import * as serviceWorker from './serviceWorker';

import {IntlProvider,} from 'react-intl';
import ca from "./translations/ca.json";
import en from "./translations/en.json";
import es from "./translations/es.json";
import fr from "./translations/fr.json";

const qs = QueryStringRetriever();
let locale = navigator.language;
if (qs.language) locale = qs.language;
locale = locale.split('-')[0];
localStorage.setItem("appLanguage", locale);

function main(messages) {
    if(!(locale in messages)) locale = "en";
    ReactDOM.render(
        <IntlProvider locale={locale} key={locale} messages={messages[locale]}>
            <App />
        </IntlProvider>,
        document.getElementById('root'));
}

function destinationID(name) {
    switch(name) {
        case "engadin":
            return 119;
        case "baqueiraberet":
            return 212;
        case "boynemountain":
            return 218;
        case "copper":
            return 107;
        case "cypressmtn":
            return 176;
        case "destinationguide":
            return 229;
        case "skigranitepeak":
            return 190;
        case "vallnord":
            return 220;
        default:
            return null;
    }
}

function getTranslations() {
    const messages = {
        ca: ca,
        en: en,
        es: es,
        fr: fr
    }

    return axios.get(`https://ws.spotlio.com/services.php/i18n/general`)
    .then(async (response) => {
        if(response.status >= 400) return messages;
        if(!response.data) return messages;
        let messages2 = {
            es: response.data.find((keys) => keys.language_id === 0).keys,
            ca: response.data.find((keys) => keys.language_id === 1).keys,
            en: response.data.find((keys) => keys.language_id === 2).keys,
            fr: response.data.find((keys) => keys.language_id === 3).keys,
            de: response.data.find((keys) => keys.language_id === 4).keys,
            it: response.data.find((keys) => keys.language_id === 5).keys,
            ru: response.data.find((keys) => keys.language_id === 6).keys,
            pt: response.data.find((keys) => keys.language_id === 7).keys,
            oc: response.data.find((keys) => keys.language_id === 8).keys
        }
        if(qs.destination) {
            const destID = destinationID(qs.destination);
            if(!destID) return;
            await axios.get(`https://ws.spotlio.com/services.php/i18n/all?destination_id=${destID}`)
            .then((response) => {
                messages2 = {
                    es: response.data.result[0] && Object.keys(response.data.result[0]).length > 0 ? response.data.result[0] : messages2.es,
                    ca: response.data.result[1] && Object.keys(response.data.result[1]).length > 0 ? response.data.result[1] : messages2.ca,
                    en: response.data.result[2] && Object.keys(response.data.result[2]).length > 0 ? response.data.result[2] : messages2.en,
                    fr: response.data.result[3] && Object.keys(response.data.result[3]).length > 0 ? response.data.result[3] : messages2.fr,
                    de: response.data.result[4] && Object.keys(response.data.result[4]).length > 0 ? response.data.result[4] : messages2.de,
                    it: response.data.result[5] && Object.keys(response.data.result[5]).length > 0 ? response.data.result[5] : messages2.it,
                    ru: response.data.result[6] && Object.keys(response.data.result[6]).length > 0 ? response.data.result[6] : messages2.ru,
                    pt: response.data.result[7] && Object.keys(response.data.result[7]).length > 0 ? response.data.result[7] : messages2.pt,
                    oc: response.data.result[8] && Object.keys(response.data.result[8]).length > 0 ? response.data.result[8] : messages2.oc,
                }
            }).catch((error) => console.log(error))
        }
        return messages2;
    }).catch((e) => {
        return messages;
    })
}

getTranslations().then(main);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();