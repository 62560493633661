import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

// https://material-ui.com/customization/default-theme/
export default responsiveFontSizes(createMuiTheme({
    typography: {
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 700,
        fontFamily: '"Helvetica", "Arial", sans-serif'
    },
    palette: {
        background: {
            default: '#ffffff'
        }
    }
}));