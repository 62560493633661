import React from 'react';
import Lottie from 'react-lottie';
import animationData from './loading.json';
import copperLoading from './copper_loading.json';

import './index.scss';

export default function Loading(props) {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: props && props.destination && props.destination === "copper" ? copperLoading : animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    if(!props.destination) return <div></div>;
    return (
        <div className="loadingAnimation">
            <div className="dots">
                <div className="logoWrapper">
                    <div className="logoInnerWrapper">
                        { props.logo && <img className="logo" src={props.logo} alt="" /> }
                        <Lottie options={defaultOptions}
                            height={300}
                            width={300}
                            isStopped={false}
                            isPaused={false}
                            className="lottie"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
