import pkg from "../../package.json";
const config = {
    vendors: [
        {
            name: "Spotlio Tracking",
            api: {
                pageView(eventName, params) {
                    console.log(eventName, params);
                },
                track(eventName, params) {
                    console.log(eventName, params);
                }
            }
        }
    ],
    pageDefaults: routeState => {
        const timestamp = new Date();
        return {
            timestamp,
            build: pkg.version,
            siteName: "Spotlio Widgets",
            category: routeState.pathname
        };
    },
    pageViewEvent: "pageLoad",
    debug: false
};

export default config;