import React, { PureComponent, Suspense } from 'react';
import styled from 'styled-components';

import { ThemeProvider as MaterialUIProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';

import Loader from '../Animations/CustomLoader';

import QueryStringRetriever from '../../utils/QueryStringRetriever';

import MaterialUITheme from './theme';
import './index.scss';

const StyledLayout = styled.div`
    background: url(${props=>props.background}) no-repeat center center fixed;
    background-size: cover;
    height: 100%;
    .leaderboardPage{
        .MuiTabs-scroller{
            .MuiTabs-indicator{
                background-color: ${props=>props.styles.tab_accent}
            }
        }
        .tableTopBarContainer, .MuiAppBar-colorPrimary{
            background-color: ${props=>props.styles.main_color}
        }
    }
    
`;

class Layout extends PureComponent{
    constructor(props){
        super(props)
        this.state = {
            loading: true,
            error: false,
            background: null
        };
    }
    
    componentDidMount(){
        this.handleGetConfig()
    }

    handleGetConfig(){
        let queryString = QueryStringRetriever()
        if(!queryString.destination) queryString.destination = "copper"
        if(queryString.destination){
            import("../../settings/"+queryString.destination+".json").then(config=>{
                let newState = {config: config}
                if(config.background) newState.background=config.background;
                localStorage.setItem("settings", JSON.stringify(config))
                this.setState({...this.state, ...newState, loading: false})
            }).catch(()=>{
                this.setState({...this.state, error: true, loading: false});
            })
        }
    }

    handleRenderContent(){
        if(this.state.error){
            return(
                <div className="error">
                    <p>No destination found</p>
                </div>
            )
        }
        return (
            <StyledLayout background={this.state.background} styles={this.state.config.styles}>
                <Container className="viewWrapper" component="section">
                    {this.props.children}
                </Container>
            </StyledLayout>
        )
    }

    render() {
        return (
        <Suspense fallback={<Loader />}>
            <MaterialUIProvider theme={MaterialUITheme}>
                <CssBaseline />
                {this.state.loading ? <Loader /> : this.handleRenderContent()}
            </MaterialUIProvider>
        </Suspense>
        )
    }
}

export default Layout;