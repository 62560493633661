import React from 'react';

// import { setToLocalStorage, getFromLocalStorage } from '../utils/localStorage';
import Router from '../utils/router';

const initialize = () => {
  // get config files and set to local storage, etc...
  console.log('initialize app')
};

export default () => {
  initialize();

  return (<Router />);
}